import qs from 'qs'

import { eventsQueryDateTimeStart, eventsQueryDateRangeFilter } from '~/composables/events/filter-date-objects'

export const queryEventEntryThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const dateTimeStartFilter = eventsQueryDateTimeStart(options?.dateStart, options?.dateEnd)
  const dateRangeFilter = eventsQueryDateRangeFilter(options?.dateStart, options?.dateEnd)

  const dateTimeFilters = options?.dateTimeFilters || { ...dateTimeStartFilter, ...dateRangeFilter }
  const permanentEventFilters = options?.permanentEventFilters || {}

  const defaultFilters = {
    $and: [
      {
        event_categories: {
          id: { $in: options.categoryIds || [] }
        }
      },
      {
        venue_entry: {
          id: { $in: options?.venueIds || [] }
        }
      },
      {
        $or: [
          {
            venue_entry: {
              city_entry: {
                id: { $in: options?.cityIds || [] }
              }
            }
          },
          {
            // potential
            event_locations: {
              city_entry: {
                id: { $in: options?.cityIds || [] }
              }
            }
          },
          // Maybe
          {
            focus_week_entry: {
              city_entry: {
                id: { $in: options?.cityIds || [] }
              }
            }
          },
        ]
      },
      {
        $or: [
          {
            venue_entry: {
              city_entry: {
                cluster_entry: {
                  id: { $in: options?.clusterIds || [] }
                }
              }
            }
          },
          {
            // no city_entry
            event_locations: {
              cluster_entry: {
                id: { $in: options?.clusterIds || [] }
              }
            }
          },
          {
            focus_week_entry: {
              city_entry: {
                cluster_entry: {
                  id: { $in: options?.clusterIds || [] }
                }
              }
            }
          }
        ]
      },
      {
        participant_entries: {
          id: { $in: options?.participantIds || [] }
        }
      },
      permanentEventFilters
    ],
    ...dateTimeFilters
  }

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_time_start:desc', 'date_time_end:desc', `${sortKey}:asc`],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 6,
      },
      filters: options?.filters || defaultFilters,
      ...thumbnailEventEntry
    },
    {
      encodeValuesOnly: true
    }
  )

  return `event-entries?${query}`
}
